import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import { MarkDownAnchorElement } from '../../helpers/support/renderMarkdown';

const TermsOfUseElement = styled.div`
	font-size: ${(props) => props.theme.fontSizes.sm};
	padding-top: ${(props) => props.theme.spacings.medium};
	opacity: 0.78;
	text-align: start;

	a {
		color: ${(props) => props.theme.colors.accent};

		:hover {
			text-decoration: none;
		}

		:focus-visible {
			outline: 2px solid ${(props) => props.theme.colors.focus};
		}
	}
`;

const TermsOfUse: FunctionComponent = (props) => {
	const [{ settings }] = useStateContext();

	const termsOfUseUrl =
		settings.branding && settings.branding.termsOfUseUrl
			? settings.branding.termsOfUseUrl
			: getLabel('WidgetTermsOfUseUrl', settings.applicationTexts, true);

	const anchorProps = {
		href: termsOfUseUrl,
		target: '_blank',
		rel: 'noopener noreferrer'
	};

	return (
		<TermsOfUseElement data-minddclass="termsofuse">
			<MarkDownAnchorElement {...anchorProps}>{getLabel('WidgetTermsOfUseButtonLabel', settings.applicationTexts, true)}</MarkDownAnchorElement>
		</TermsOfUseElement>
	);
};

export default TermsOfUse;
