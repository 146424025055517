import React, { useState, useEffect, useRef } from 'react';
import S from './CallbackRequestScheduler.styled';
import AwesomeButton from '../AwesomeButton/AwesomeButton';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { CallPostCallBackEmail, CallVerifyChosenTimeSlot } from '../../helpers/services';
import WidgetDrawer from '../WidgetDrawer/WidgetDrawer';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import { useTheme } from 'styled-components';
import type { ActivityStepCallbackRequestScheduler } from '../../models/widget/ActivitySteps';
import type { CallbackRequestSchedule, ContactRequestFields, TimeSlot } from '../../models';
import SD from './drawerStyles';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCalendarCheck, faCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { fullDate, timeInterval } from '../../helpers/support/dateTimeHelpers';
import { fillMissingDates } from './fillMissingDates';
import DaySelector from './DaySelector';
import { getLabel } from '../../helpers/constants/getLabels';
import { default as SA } from '../Activity/Activity.styled';
import { default as SAA } from '../ActivityAdvice/ActivityAdvice.styled';
import { ActivityProps } from '../Activity';
import { GetCallbackTimeSlots } from '../../helpers/services/CallGetCallbackTimeslots';

interface CallbackRequestSchedulerProps extends ActivityProps, ActivityStepCallbackRequestScheduler {
	urgence: string;
}

const CallbackRequestScheduler = ({ urgence, handleActivityResponse, disabled = false, ...props }: CallbackRequestSchedulerProps) => {
	const [schedule, setSchedule] = useState<CallbackRequestSchedule | null>(null);
	const [{ settings, session, conversation }, dispatch] = useStateContext();
	const [isDisabled, setIsDisabled] = useState(disabled);
	const [isSending, setIsSending] = useState(false);
	const [isError, setIsError] = useState(false);
	const themeContext = useTheme();
	const isMobile = useWindowSize().width < themeContext.breakpoints.medium;
	const drawerContainerRef = useRef<HTMLDivElement>(null);
	const nodeRef = useRef(null); // used for transition
	const callbackRequestSchedulerActivityRef = useRef<HTMLDivElement>(null);

	const [scheduleOption, setScheduleOption] = useState('asap');
	const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(null);
	const [userHasSelectedTimeSlot, setUserHasSelectedTimeSlot] = useState(false);
	const [unavailableSelectedTimeSlot, setUnavailableSelectedTimeSlot] = useState(false);

	const handleOnReleaseDrawer = (event: React.PointerEvent<HTMLDivElement>, open: boolean) => {
		if (!open) {
			setScheduleOption('asap');
		}
	};

	const handleCloseDrawer = () => {
		setScheduleOption('asap');
	};

	useEffect(() => {
		props.setDisableNext(isSending);
		props.setIsLoading(isSending);
	}, [isSending]);

	useEffect(() => {
		if (props.schedule) {
			setSchedule(props.schedule.timeSlots ? fillMissingDates(props.schedule) : props.schedule);
		}
	}, [props.schedule]);

	useEffect(() => {
		if (schedule && schedule.timeSlots && schedule.timeSlots.length >= 1) {
			// note: schedule.firstAvailable is NOT used here
			setSelectedTimeSlot(schedule.timeSlots[0].slots[0]);
		}
	}, [schedule]);

	useEffect(() => {
		if (!disabled) {
			props.setHandleNext(() => () => {
				handleConfirm()
					.then(props.handleNext)
					.catch((e) => {
						setIsError(true);
						setIsDisabled(false);
						setIsSending(false);
						console.error(e);
					});
			});
		}
	}, [selectedTimeSlot, disabled]);

	// day index, first day is selected by default
	const [selectedDay, setSelectedDay] = useState(0);

	const selectDay = (dayIndex: number) => {
		setSelectedDay(dayIndex);
	};

	const resetUserSelection = () => {
		setUserHasSelectedTimeSlot(false);
		if (schedule && schedule.timeSlots && schedule.timeSlots.length >= 1) {
			setSelectedTimeSlot(schedule.timeSlots[0].slots[0]);
		}
	};

	const handleConfirm = async () => {
		let fields: ContactRequestFields | null = null;
		const obj = conversation.find((c) => c.type === 'callbackRequestScheduler') as ActivityStepCallbackRequestScheduler | undefined;
		if (obj) {
			fields = obj.fields as ContactRequestFields;
		}

		setIsDisabled(true);
		setIsSending(true);

		if (selectedTimeSlot && fields) {
			const verifiedTimeSlot = await CallVerifyChosenTimeSlot(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				timeslot: selectedTimeSlot.start
			});

			// If the timeslot verification fails, stop the process and inform the user
			if (!verifiedTimeSlot || !verifiedTimeSlot.isAvailable) {
				setUnavailableSelectedTimeSlot(true);
				setIsError(true);
				setIsDisabled(false);
				setIsSending(false);

				// Fetch fresh timeslots after verification failure
				const freshSchedule = await GetCallbackTimeSlots(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					callbackLocation: fields.CallbackLocation || undefined
				});

				setSchedule(freshSchedule?.timeSlots ? fillMissingDates(freshSchedule) : freshSchedule);
				resetUserSelection();
				setScheduleOption('asap');
			} else {
				const result = await CallPostCallBackEmail(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					userAgreedToPrivacyStatement: true, // user already agreed at the previous step, the form
					user: {
						name: fields.Name || undefined,
						givenName: fields.GivenName || undefined,
						nameParticle: fields.NameParticle || undefined,
						surname: fields.Surname || undefined,
						dateOfBirth: fields.Dob || undefined,
						gender: fields.Gender || undefined,
						phoneNumber: fields.Tel || undefined,
						address: fields.Address || undefined,
						houseNumber: fields.HouseNumber || undefined,
						zipCode: fields.ZipCode || undefined,
						doctor: fields.Doctor || undefined,
						hapLocation: fields.HapLocation || undefined,
						bsn: fields.Bsn || undefined,
						email: fields.Email || undefined,
						imageData: fields.ImageData || undefined,
						imageContentType: fields.ImageContentType || undefined,
						comment: fields.Comment || undefined,
						callbackLocation: fields.CallbackLocation || undefined
					},
					languageCode: settings.selectedLanguage.code,
					chosenTimeslot: selectedTimeSlot.start
				});

				if (result && 'message' in result) {
					if (result.message === 'success') {
						setIsSending(false);
						dispatch({
							type: 'conversation/addStep',
							step: {
								id: 'requestCallbackEnd',
								type: 'requestCallbackEnd',
								callbackTime: result.callbackTime,
								urgence: urgence,
								disableBackButton: true,
								timeSlot: selectedTimeSlot,
								phoneNumber: fields.Tel
							}
						});
						void handleActivityResponse({ selectedTimeSlot: selectedTimeSlot, callbackTime: result.callbackTime });
					} else {
						setIsError(true);
						setIsDisabled(true);
						setIsSending(false);
						void handleActivityResponse({ message: 'error' });
					}
				}
			}
		} else {
			setIsSending(false);
			setIsDisabled(false);
		}
	};

	const handleOnExited = () => {
		if (callbackRequestSchedulerActivityRef.current) {
			callbackRequestSchedulerActivityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const handleTimeSlotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function isTimeSlot(o: any): o is TimeSlot {
			return 'start' in o && 'end' in o;
		}

		const newTimeSlot: unknown = JSON.parse(event.target.value);
		if (isTimeSlot(newTimeSlot)) {
			setSelectedTimeSlot(newTimeSlot);
			setUserHasSelectedTimeSlot(true);
			setUnavailableSelectedTimeSlot(false);
		}
	};

	return (
		<SA.ActivityBubble ref={callbackRequestSchedulerActivityRef}>
			{schedule && schedule.timeSlots && schedule.timeSlots.length >= 1 && (
				<>
					<div>{getLabel('timeSlotCallbackRequestIntro', settings.applicationTexts)}</div>
					<S.Container>
						<>
							{unavailableSelectedTimeSlot && (
								<S.TimeSlotUnverifiedAlert>{getLabel('TimeSlotUnavailableLabel', settings.applicationTexts, true)}</S.TimeSlotUnverifiedAlert>
							)}
							<S.RadioButtonLabel
								onClick={() => {
									if (isDisabled) return;
									setScheduleOption('asap');
									resetUserSelection();
								}}
								$isSelected={scheduleOption === 'asap'}
								$isExpandable={false}
								$disabled={isDisabled}
							>
								<FontAwesomeIcon icon={faClock} style={{ fontSize: '20px' }} />
								<S.RadioButtonLabelText>
									{/* Zo snel mogelijk */}
									<h3>{getLabel('timeSlotCallbackRequestAsapLabel', settings.applicationTexts, true)}</h3>
									{scheduleOption === 'asap' && (
										<S.DateAndTimeSlotText>
											<span>{fullDate(schedule.timeSlots[0].date, settings.selectedLanguage.code, true)}</span>
											<span>
												{timeInterval(
													schedule.timeSlots[0].slots[0].start,
													schedule.timeSlots[0].slots[0].end,
													settings.selectedLanguage.code
												)}
											</span>
										</S.DateAndTimeSlotText>
									)}
								</S.RadioButtonLabelText>
								{scheduleOption === 'asap' && <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px' }} className="selected" />}
								{!(scheduleOption === 'asap') && <FontAwesomeIcon icon={faCircle} style={{ fontSize: '20px' }} />}
							</S.RadioButtonLabel>
							<S.RadioButtonLabel
								onClick={() => {
									if (isDisabled) return;
									setScheduleOption('selfSchedule');
								}}
								$isSelected={scheduleOption === 'selfSchedule'}
								$isExpandable={!userHasSelectedTimeSlot}
								$disabled={isDisabled}
								style={{ marginTop: '20px' }}
							>
								<FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: '20px' }} />
								<S.RadioButtonLabelText className="RadioButtonLabelText">
									{/* Zelf inplannen */}
									<h3>{getLabel('timeSlotCallbackRequestSelfScheduleLabel', settings.applicationTexts, true)}</h3>
									{!userHasSelectedTimeSlot && (
										// Kies een tijd
										<p>{getLabel('timeSlotCallbackRequestChooseTimeSlotLabel', settings.applicationTexts, true)}</p>
									)}
									{userHasSelectedTimeSlot && selectedTimeSlot && (
										<S.DateAndTimeSlotText>
											<span>{fullDate(selectedTimeSlot.start, settings.selectedLanguage.code, true)}</span>
											<span>{timeInterval(selectedTimeSlot.start, selectedTimeSlot.end, settings.selectedLanguage.code)}</span>
											<AwesomeButton variant="link" onClick={() => resetUserSelection()} disabled={isDisabled}>
												{getLabel('editButton', settings.applicationTexts, true)}
											</AwesomeButton>
										</S.DateAndTimeSlotText>
									)}
								</S.RadioButtonLabelText>
								{scheduleOption === 'selfSchedule' && (
									<FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '20px' }} className="selected" />
								)}
								{scheduleOption !== 'selfSchedule' && <FontAwesomeIcon icon={faCircle} style={{ fontSize: '20px' }} />}
							</S.RadioButtonLabel>
						</>
						{/* mobile */}
						{isMobile && (
							<WidgetDrawer
								ref={drawerContainerRef}
								isOpen={scheduleOption === 'selfSchedule' && !userHasSelectedTimeSlot}
								onRelease={handleOnReleaseDrawer}
								container={drawerContainerRef.current}
								showHandle={false}
								snapPoints={[]}
							>
								<SD.DrawerHeader>
									<SD.DrawerBubbleSubTitle>
										{getLabel('timeSlotCallbackRequestSelfScheduleLabel', settings.applicationTexts, true)}
									</SD.DrawerBubbleSubTitle>
									<SD.CloseDrawerButton onClick={() => handleCloseDrawer()} variant="link">
										{getLabel('AdviceButtonLabelClose', settings.applicationTexts, true)}
									</SD.CloseDrawerButton>
								</SD.DrawerHeader>
								<S.DateAndTimeSlotContainer>
									{/* only show DaySelector when there are multiple days */}
									{schedule.timeSlots.length > 1 && (
										<DaySelector schedule={schedule} selectedDay={selectedDay} selectDay={selectDay}></DaySelector>
									)}
									<S.TimeSlotContainer>
										<S.AvailableTimeSlotsText>
											{/* Vrije tijden  */}
											{getLabel('timeSlotCallbackRequestAvailableSlotsLabel', settings.applicationTexts, true)}
											&nbsp;
											{fullDate(schedule.timeSlots[selectedDay].date, settings.selectedLanguage.code)}
										</S.AvailableTimeSlotsText>
										<S.ScrollableContentWrapper data-vaul-no-drag>
											{schedule.timeSlots[selectedDay].slots.map((slot: TimeSlot, index: number) => (
												<S.TimeSlotRadioButtonLabel key={index}>
													<FontAwesomeIcon icon={faClock} style={{ fontSize: '20px' }} />
													<span>{timeInterval(slot.start, slot.end, settings.selectedLanguage.code)}</span>
													<input
														type="radio"
														name="timeSlot"
														value={JSON.stringify(slot)}
														checked={selectedTimeSlot && userHasSelectedTimeSlot ? selectedTimeSlot.start === slot.start : false}
														onChange={handleTimeSlotChange}
													/>
												</S.TimeSlotRadioButtonLabel>
											))}
										</S.ScrollableContentWrapper>
									</S.TimeSlotContainer>
								</S.DateAndTimeSlotContainer>
							</WidgetDrawer>
						)}
						{/* desktop */}
						<CSSTransition
							nodeRef={nodeRef}
							in={!isMobile && scheduleOption === 'selfSchedule' && !userHasSelectedTimeSlot}
							timeout={300}
							classNames="DateAndTimeSlotContainerDesktop"
							onExited={() => handleOnExited()}
						>
							<S.DateAndTimeSlotContainerDesktop ref={nodeRef}>
								<>
									{/* Vrije tijden */}
									<S.AvailableTimeSlotsText>
										{getLabel('timeSlotCallbackRequestAvailableSlotsLabel', settings.applicationTexts, true)}
										&nbsp;
										{fullDate(schedule.timeSlots[selectedDay].date, settings.selectedLanguage.code)}
									</S.AvailableTimeSlotsText>

									{/* only show DaySelector when there are multiple days */}
									{schedule.timeSlots.length > 1 && (
										<DaySelector schedule={schedule} selectedDay={selectedDay} selectDay={selectDay}></DaySelector>
									)}
								</>

								<S.TimeSlotContainer>
									{schedule.timeSlots &&
										schedule.timeSlots[selectedDay].slots.map((slot: TimeSlot, index: number) => (
											<S.TimeSlotRadioButtonLabel key={index}>
												<FontAwesomeIcon icon={faClock} style={{ fontSize: '20px' }} />
												<span>{timeInterval(slot.start, slot.end, settings.selectedLanguage.code)}</span>
												<input
													type="radio"
													name="timeSlot"
													value={JSON.stringify(slot)}
													checked={selectedTimeSlot && userHasSelectedTimeSlot ? selectedTimeSlot.start === slot.start : false}
													onChange={handleTimeSlotChange}
												/>
											</S.TimeSlotRadioButtonLabel>
										))}
								</S.TimeSlotContainer>
							</S.DateAndTimeSlotContainerDesktop>
						</CSSTransition>
					</S.Container>
				</>
			)}
		</SA.ActivityBubble>
	);
};

export default CallbackRequestScheduler;
