import React, { FunctionComponent } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import S from './styled';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabelWithOverride } from '../../helpers/constants/getLabelWithOverride';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';

const ActivityWelcome: FunctionComponent = (props) => {
	const [{ settings }] = useStateContext();
	const [, widgetIsSmall] = useWidgetIsNarrow();
	const welcomeText = getLabelWithOverride(settings.welcome_text, 'WidgetWelcomeText', settings.applicationTexts, settings.selectedLanguage.code);
	const showTermsOfUse = !widgetIsSmall && settings.widgetType !== 'Compact';

	return (
		<S.ClearActivityBubble>
			<SA.BubbleTitle>{welcomeText}</SA.BubbleTitle>
			{showTermsOfUse && <TermsOfUse />}
		</S.ClearActivityBubble>
	);
};

export default ActivityWelcome;
