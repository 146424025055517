/**
 * Be able to directly start a triage (ie corona case)
 * To enable DirectTriage use the prefill settings in WidgetConfig, example:
 * 	prefill: {
 * 		active: true,
 *  	gender: "Male",
 * 		dob:{
 * 			day: 1,
 * 			month: 1,
 * 			year: 1990
 * 		},
 * 		bodypart: %REACT_APP_PREFILL_BODYPART%,
 *  	complaint: %REACT_APP_PREFILL_COMPLAINT%,
 * 	}
 */

import React, { useEffect, useState } from 'react';

import { default as SA } from '../Activity/Activity.styled';
import SAW from '../ActivityWelcome/styled';

import ActivityWelcome from '../ActivityWelcome/ActivityWelcome';
import ActivityTriage from '../ActivityTriage/ActivityTriage';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import { useDetermineNextStep } from '../../helpers/hooks/nextStep/useDetermineNextStep';
import { CallGetStartQuestion, CallStartSession } from '../../helpers/services';
import { validateSession } from '../../helpers/support/validateSession';
import useWidgetIsNarrow from '../../helpers/hooks/useWidgetIsNarrow';
import type { ActivityStepDirectTriage } from '../../models';
import { ActivityProps } from '../Activity';
import Preloader from '../Preloader/Preloader';
import StartButton from '../StartButton/StartButton';
import { useTheme } from 'styled-components';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { getLabel } from '../../helpers/constants/getLabels';

type DirectTriageProps = ActivityProps & {
	currentStep: ActivityStepDirectTriage;
};

const ActivityDirectTriage = (props: DirectTriageProps) => {
	const [{ profile, settings, session, modal }, dispatch] = useStateContext();
	const handleToggleModal = useToggleModal();
	const determineNextStep = useDetermineNextStep();
	const [widgetIsNarrow, widgetIsSmall] = useWidgetIsNarrow();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const { currentStep } = props;
	const themeContext = useTheme();

	const handleQuestionAnswer = async (questionId: number, answer: string | number) => {
		const updatedQuestions = currentStep.questions.map((q) => (q.question.id === questionId ? { ...q, answer } : q));

		const updatedStep = {
			...currentStep,
			questions: updatedQuestions
		};
		setLoading(true);
		const nextStep = await determineNextStep(updatedStep);
		setLoading(false);

		if (!nextStep) return;

		updatedStep.answer = { message: 'completed' };
		dispatch({
			type: 'conversation/updateStep',
			step: updatedStep
		});

		dispatch({
			type: 'conversation/setNextStep',
			currentStepId: currentStep.id,
			step: nextStep
		});
	};

	const handleStart = () => {
		if (!props.modalOpen) {
			void handleToggleModal();
		}
		if (props.currentStep.answer) {
			props.handleNext?.();
		}
	};

	useEffect(() => {
		if (settings.prefill && settings.prefill.active) {
			const fetchStartQuestion = async () => {
				if (!currentStep.questions[0] || settings.selectedLanguage.code !== currentStep.questionLanguageCode) {
					if (!validateSession(session)) {
						const startSessionResult = await CallStartSession(settings.ApiKey, {
							widgetType: settings.widgetType,
							restart: true,
							externalId: profile.externalId,
							phoneNumber: profile.phoneNumber,
							languageCode: settings.selectedLanguage.code
						});

						if (startSessionResult) {
							dispatch({
								type: 'updateSession',
								session: {
									id: startSessionResult.sessionId,
									token: startSessionResult.sessionToken
								}
							});
						}
					} else {
						setLoading(true);
						const startQuestion = await CallGetStartQuestion(settings.ApiKey, {
							triageId: Number(settings.prefill?.complaint),
							age: 0,
							requestType: 'StartTriage',
							languageCode: settings.selectedLanguage.code,
							sessionId: session.id,
							sessionToken: session.token
						});
						setLoading(false);

						if (startQuestion && startQuestion.startQuestion) {
							const updatedStep = {
								...currentStep,
								questions: [
									{
										question: startQuestion.startQuestion
									}
								],
								questionLanguageCode: settings.selectedLanguage.code
							};
							dispatch({
								type: 'conversation/updateStep',
								step: updatedStep
							});
						} else {
							setError(true);
						}
					}
				}
			};
			void fetchStartQuestion();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings.ApiKey, settings.selectedLanguage, session]);

	return (
		<SAW.ActivityGroup $modalClosed={!props.modalOpen} $narrow={widgetIsSmall}>
			<div>
				<SA.ActivityBubble>
					{currentStep.questions[0] && (
						<ActivityTriage
							key={currentStep.questions[0].question.id}
							isLastQuestion={true}
							isFirstQuestion={true}
							{...{ ...props, ...currentStep.questions[0], disabled: props.disabled || loading }}
							handleQuestionAnswer={handleQuestionAnswer}
							widgetIsNarrow={widgetIsNarrow}
						/>
					)}

					{loading ? (
						<Preloader style={{ marginTop: themeContext.spacings.large }} />
					) : (
						<div style={{ height: `calc(${themeContext.spacings.large} + ${themeContext.spacings.small})` }} />
					)}

					{error && <ErrorMessage showError>{getLabel('DirectTriageNoStartQuestionFound', settings.applicationTexts)}</ErrorMessage>}
				</SA.ActivityBubble>
				{!modal.open && props.isFirstActivity && <StartButton onStart={handleStart} disabled={loading} />}
			</div>
			{settings.widget_showWelcomeText && props.isFirstActivity && !props.modalOpen && <ActivityWelcome />}
		</SAW.ActivityGroup>
	);
};

export default ActivityDirectTriage;
